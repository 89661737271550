<template>
  <b-card>
    <FormBuilder
      label="Multiple Game"
      create
      :fields="fields"
      :loading-submit="$store.state.multipleGame.loadingSubmit"
      @submit="submitData"
    />
  </b-card>
</template>

<script>
import FormBuilder from '@/layouts/components/FormBuilder.vue'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormBuilder,
  },
  data() {
    return {
      // Fields
      fields: [
        {
          key: 'title',
          label: 'Title',
          required: true,
        },
        {
          key: 'sort',
          label: 'Sort',
          type: 'number',
          prefix: '#',
          required: true,
        },
        {
          key: 'games',
          label: 'Game',
          option_key: 'id',
          type: 'multiple',
          text: 'text',
          required: true,
          actionSearch: 'multipleGame/search',
        },
      ],
    }
  },
  methods: {
    submitData(data, callback) {
      this.$store.dispatch('multipleGame/create', {
        ...data,
        roles: ['customer'],
      })
        .then(callback)
    },
  },
}
</script>

  <style scoped>

  </style>
